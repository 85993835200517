export default {
  cake: {
    137: '0x7cc15fef543f205bf21018f038f591c6bada941c',
    // 137: '0x76b7f60963f116a1f70b9c5e0f035bb129ac627b', // test
    666: 'This is CUB token',
  },
  masterChef: {
    137: '0xef79881df640b42bda6a84ac9435611ec6bb51a4',
    // 137: '0x5C2dF1Cb58BEA84Fba074e179D91245738F18187', // test
  },
  kingdoms: {
    137: '0xef79881df640b42bda6a84ac9435611ec6bb51a4',
    // 137: '0x5C2dF1Cb58BEA84Fba074e179D91245738F18187', // test
  },
  sushi: {
    137: '0x0769fd68dfb93167989c6f7254cd0d766fb2841f',
  },
  xstaker: {
    137: '0x905e21f6c4cb1ad789ced61cd0734590a4542346',
    // 137: '0x3dA925909d5DfB31dCee1ACAD52a4F28a379d0d4', // test
  },
  curve: {
    137: '0x3B6B158A76fd8ccc297538F454ce7B4787778c7C',
  },
  curveAave: {
    137: '0x19793B454D3AfC7b454F206Ffe95aDE26cA6912c',
  },
  airdrop: {
    137: '0x07E61e576D0C833ed15bD2186B152082407422a9',
  },


  bnbDividends: {
    137: '0xa22efc88F3Eb641D881D0807dc8E305d71920cAB',
  },
  pcsV2masterChef: {
    137: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  bakery: {
    137: '0x20ec291bb8459b6145317e7126532ce7ece50137f',
  },
  belt: {
    137: '0xD4BbC80b9B102b77B21A06cb77E954049605E6c1',
  },
  wbnb: {
    137: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  lottery: {
    137: '',
  },
  lotteryNFT: {
    137: '',
  },
  multiCall: {
    137: '0xb4f7F21714a4f51bb0A5212dfFEC851B0d09Ec77',
  },
  busd: {
    137: '0xe9e7cea3dedca5984780bafc599bd69add087d137',
  },
  cake_real: {
    137: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  },
  sousChef: {
    97: '',
  },
  pancakeProfile: {
    137: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
  },
  pancakeRabbits: {
    137: '',
  },
  bunnyFactory: {
    137: '0x428ad484a8f13a0535bbacf55274deb5e8328e44',
  },
  claimRefund: {
    137: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
  },
  pointCenterIfo: {
    137: '',
  },
  bunnySpecial: {
    137: '',
  },
  tradingCompetition: {
    137: '',
  },
  easterNft: {
    137: '',
  },
  cakeVault: {
    137: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
  },
  predictions: {
    137: '',
  },

}
