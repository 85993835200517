import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from 'utils/bigNumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3

export const TOKEN = 'CUB'

// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 18 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 12 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in components/CakeStats.tsx = 22 (40 - Amount sent to burn pool)

export const CAKE_PER_BLOCK = new BigNumber(0.25)
export const BLOCKS_PER_YEAR = new BigNumber(10512000)
export const PCSCAKE_PER_BLOCK = new BigNumber(40)
export const PCSBLOCKS_PER_YEAR = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365) // 10512000
export const PCSCAKE_PER_YEAR = PCSCAKE_PER_BLOCK.times(PCSBLOCKS_PER_YEAR)
export const BAKE_PER_BLOCK = new BigNumber(22)
export const BAKE_PER_YEAR = BAKE_PER_BLOCK.times(BLOCKS_PER_YEAR)
export const BELT_PER_BLOCK = new BigNumber(1.178)
export const BELT_PER_YEAR = BELT_PER_BLOCK.times(BLOCKS_PER_YEAR)
// export const SUSHI_PER_BLOCK = new BigNumber(12.93980367)
// export const SUSHI_BLOCKS_PER_DAY = 6500
// export const SUSHI_BLOCKS_PER_MONTH = 191430
// export const SUSHI_PER_YEAR = SUSHI_PER_BLOCK.times(SUSHI_BLOCKS_PER_MONTH * 12)
export const SUSHI_PER_YEAR = new BigNumber(3155692.6)

// export const PCS_BLOCKS_PER_YEAR = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365) // 10512000
export const BASE_URL = 'https://cubdefi.com'
export const BASE_EXCHANGE_URL = 'https://app.sushi.com'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_EXCHANGE_URL}/add`
export const BASE_LIQUIDITY_POOL_URL = `${BASE_EXCHANGE_URL}/pool`
export const SUSHI_EXCHANGE_URL = 'https://app.sushi.com'
export const SUSHI_ADD_LIQUIDITY_URL = `${SUSHI_EXCHANGE_URL}/add`
export const SUSHI_LIQUIDITY_POOL_URL = `${SUSHI_EXCHANGE_URL}/pool`
export const CURVE_EXCHANGE_URL = 'https://polygon.curve.fi/'
export const BELT_EXCHANGE = 'https://belt.fi/bsc'
export const BASE_SCAN_URL = 'https://polygonscan.com'
export const LOTTERY_MAX_NUMBER_OF_TICKETS = 50
export const LOTTERY_TICKET_PRICE = 1
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const AAVE_TOKEN_USD = 1.05
