import Web3 from 'web3'
import axios from 'axios'
import { getSushiAddress, getMasterChefAddress, getCurveAddress, getCurveAaveAddress } from 'utils/addressHelpers'
// import farms from 'config/constants/farms';

const SUSHI_ABI = require('config/abi/sushi.json')
const CURVE_ABI = require('config/abi/curve.json')
const CURVE_AAVE_ABI = require('config/abi/curveAave.json')
const MASTERCHEF_ABI = require('config/abi/masterchef.json')
// const SUSHI_ABI = require('config/abi/sushi.json')
// const XPOLYCUB_ABI = require('config/abi/xpolycub.json')

const web3 = new Web3(new Web3.providers.HttpProvider('https://polygon-rpc.com'));

const sushiContract = getSushiAddress();
const curveContract = getCurveAddress();
const curveAaveContract = getCurveAaveAddress();
const masterContract = getMasterChefAddress();

export const getWETHMATICAmount = async () => {
  const contract = new web3.eth.Contract(SUSHI_ABI, sushiContract);
  const call = await contract.methods.userInfo(0, '0x4E61bdF08995C760C040498E6CBF58b633EbB62').call();
  return (call && call.amount && Number(call.amount)) || 0
}

export const getWETHWBTCAmount = async () => {
  const contract = new web3.eth.Contract(SUSHI_ABI, sushiContract);
  const call = await contract.methods.userInfo(3, '0x1E3C18Fd954B949AE7AC2d51443F03C162414c47').call();
  // const call = await contract.methods.userInfo(0, '0x35f38a37e3e6c0c9d5bae20cf9a89a0234d847b9').call(); // test
  return (call && call.amount && Number(call.amount)) || 0
}

export const getWETHDAIAmount = async () => {
  const contract = new web3.eth.Contract(SUSHI_ABI, sushiContract);
  const call = await contract.methods.userInfo(5, '0x878AA3d8F7E42BDf8b3c158a7Fd0B7E3ef05D323').call();
  // const call = await contract.methods.userInfo(1, '0x0f0b828d02961a9840c4e606ae678b37058e1152').call(); // test
  return (call && call.amount && Number(call.amount)) || 0
}

export const getCRVUSDBTCETHAmount = async () => {
  const contract = new web3.eth.Contract(CURVE_ABI, curveContract);
  const call = await contract.methods.balanceOf( '0xfbecfd9dee14f6551733b9079e2aaddec5db3b08').call();
  // console.log('call',call)
  return call && Number(call) || 0
}

export const getCRVAaveAmount = async () => {
  const contract = new web3.eth.Contract(CURVE_AAVE_ABI, curveAaveContract);
  const call = await contract.methods.balanceOf( '0x31a0937cdaaf63d8f8f180d6b61733c99ba85184').call();
  // console.log('call',call)
  return call && Number(call) || 0
}

export const getCurveAPR = async () => {
  return axios.get(process.env.REACT_APP_CURVE_SCRAPE, { timeout: 3000 }).then(result => result.data).catch(() => {
    return { atricrypto3: '19', aave: '6' }
  })
}

export const getXPolyCubPenalties = async () => {
  return axios.get(process.env.REACT_APP_XPOLYCUB_PENALTIES, { timeout: 3000 }).then(result => {
    return result.data
  }).catch(() => {
    return { week: 0, day: 0 }
  })
}

export default getWETHMATICAmount
