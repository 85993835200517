import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber'
import {
  getAddress,
  getMasterChefAddress,
  getKingdomsAddress,
  getSushiAddress,
  getCurveAddress,
  getCurveAaveAddress
} from 'utils/addressHelpers'
import { FarmConfig } from 'config/constants/types'
import { DEFAULT_TOKEN_DECIMAL, AAVE_TOKEN_USD } from 'config'
import kingdomsABI from 'config/abi/kingdoms.json'
import sushiABI from 'config/abi/sushi.json'
import {
  getWETHMATICAmount,
  getWETHWBTCAmount,
  getWETHDAIAmount,
  getCRVUSDBTCETHAmount,
  getCurveAPR,
  getCRVAaveAmount,
} from 'utils/kingdomScripts'

const fetchFarms = async (farmsToFetch: FarmConfig[]) => {
  const curveData = await getCurveAPR()
  const data = await Promise.all(
    farmsToFetch.map(async (farmConfig) => {
      // if (farmConfig.lpSymbol === 'SUSHI-MATIC LP') return null
      let lpAddress = getAddress(farmConfig.lpAddresses)
      const tokenAddress = getAddress(farmConfig.token.address)
      const quoteAddress = getAddress(farmConfig.quoteToken.address)
      // const mcAddress = farmConfig.lpSymbol === 'POLYCUB-USDC LP' ? farmConfig.vaultAddress : getMasterChefAddress()
      const mcAddress = farmConfig.vaultAddress

      let calls = [
        // Balance of token in the LP contract
        {
          address: tokenAddress,
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Balance of quote token on LP contract
        {
          address: quoteAddress,
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Balance of LP tokens in the master chef contract
        {
          address: farmConfig.isTokenOnly ? tokenAddress : lpAddress,
          name: 'balanceOf',
          params: [mcAddress],
        },
        // Total supply of LP tokens
        {
          address: lpAddress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: tokenAddress,
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: quoteAddress,
          name: 'decimals',
        },
      ]

      // Kingdoms don't use masterchef own farms, use other farms data
      if (farmConfig.isKingdom) {
        let hostMasterchef = getSushiAddress()
        let totalsAddress = farmConfig.isKingdomToken ? tokenAddress : lpAddress

        if (farmConfig.farmType === 'Curve') {
          hostMasterchef = farmConfig.pid === 1 ? getCurveAaveAddress() : getCurveAddress()
          totalsAddress = farmConfig.totalsAddress
          lpAddress = farmConfig.tokenTotalsAddress
        }

        calls = [
          // Balance of token in the LP contract
          {
            address: tokenAddress,
            name: 'balanceOf',
            params: [lpAddress],
          },
          // Balance of quote token on LP contract
          {
            address: quoteAddress,
            name: 'balanceOf',
            params: [lpAddress],
          },
          // Balance of LP tokens in the master chef contract
          // {
          //   address: totalsAddress,
          //   name: 'balanceOf',
          //   params: [hostMasterchef],
          // },
          
          {
            address: totalsAddress,
            name: 'balanceOf',
            params: [hostMasterchef],
          },
          
          // {
          //   address: totalsAddress,
          //   name: 'totalSupply',
          // },
          {
            address: totalsAddress,
            name: 'totalSupply',
          },
          // Token decimals
          {
            address: tokenAddress,
            name: 'decimals',
          },
          // Quote token decimals
          {
            address: quoteAddress,
            name: 'decimals',
          },
        ]
      }
// console.log('calls',calls)
      const multiResult = await multicall(erc20, calls)

      const [
        tokenBalanceLP,
        quoteTokenBalanceLP,
        lpTokenBalanceMC,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals,
      ] = multiResult
      if (farmConfig.lpSymbol === 'lpfarm') {
        console.log('farm',farmConfig.lpSymbol)
        console.log('h-tokenBalanceLP (main token amount in lp)',
          new BigNumber(tokenBalanceLP)
          .div(
            BIG_TEN.pow(
              new BigNumber(tokenDecimals).toNumber()
            )
          ).toFormat(18))
        console.log('h-quoteTokenBalanceLP (quote token amount pre-ratio)',
          new BigNumber(quoteTokenBalanceLP)
          .div(
            BIG_TEN.pow(
              new BigNumber(quoteTokenDecimals).toNumber()
            )
          ).toFormat(18))
        console.log('h-lpTokenBalanceMC (amount lp tokens masterchef)',new BigNumber(lpTokenBalanceMC).div(DEFAULT_TOKEN_DECIMAL).toFormat(18))
        console.log('h-lpTotalSupply (amount lp tokens)',new BigNumber(lpTotalSupply).div(DEFAULT_TOKEN_DECIMAL).toFormat(18))
        console.log('h-tokenDecimals',new BigNumber(tokenDecimals).toNumber())
        console.log('h-quoteTokenDecimals',new BigNumber(quoteTokenDecimals).toNumber())
      }

// console.log('tokenDecimals',new BigNumber(tokenDecimals).toNumber())
// console.log('quoteTokenDecimals',new BigNumber(quoteTokenDecimals).toNumber())
      let kingdomSupply = 0
      let curveAPR = ''

      if (farmConfig.isKingdom) {
        switch (farmConfig.pid) {
          // case 0:
          //   kingdomSupply = await getWETHMATICAmount()
          //   break
          case 0:
            kingdomSupply = await getCRVUSDBTCETHAmount()
            curveAPR = curveData.atricrypto3
            break
          case 1:
            kingdomSupply = await getCRVAaveAmount()
            curveAPR = curveData.aave
            break
          case 2:
            kingdomSupply = await getWETHWBTCAmount()
            break
          case 3:
            kingdomSupply = await getWETHDAIAmount()
            break
          default:
            break
        }
      }

      kingdomSupply = new BigNumber(kingdomSupply).div(DEFAULT_TOKEN_DECIMAL).toNumber() || 0

      // TESTING PURPOSES
      // kingdomSupply = Number(kingdomSupply) || 5
      // if (farmConfig.pid === 0) kingdomSupply = 0.0000001
      // else if (farmConfig.pid === 2) kingdomSupply = 1

      // BTC contract returning 18 decimals, when it shoudl be 8, fix it
      const newTokenBalanceLP = farmConfig.token.symbol === 'WBTC' || farmConfig.token.symbol === 'amWBTC'
        ? new BigNumber(tokenBalanceLP)
          .div(BIG_TEN.pow(8)).times(DEFAULT_TOKEN_DECIMAL)
        : new BigNumber(tokenBalanceLP)

      let newQuoteTokenBalanceLP = new BigNumber(quoteTokenBalanceLP)

      // Correct decimals places for WBTC, from 8 to 18
      if (farmConfig.quoteToken.symbol === 'WBTC' || farmConfig.quoteToken.symbol === 'amWBTC') {
        newQuoteTokenBalanceLP = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(8)).times(DEFAULT_TOKEN_DECIMAL)
      } else if (farmConfig.quoteToken.symbol === 'USDC') {
        // Correct decimals places for USDC, from 6 to 18
        newQuoteTokenBalanceLP = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(6)).times(DEFAULT_TOKEN_DECIMAL)
      }

if (farmConfig.lpSymbol === 'lpfarm') {
  console.log('calls',calls)
  console.log('farm',farmConfig.lpSymbol)
  console.log('h-tokenBalanceLP (main token amount in lp)',newTokenBalanceLP.div(DEFAULT_TOKEN_DECIMAL).toFormat(18))
  console.log('h-quoteTokenBalanceLP (quote token amount pre-ratio)',new BigNumber(newQuoteTokenBalanceLP).div(DEFAULT_TOKEN_DECIMAL).toFormat(18))
  console.log('h-lpTokenBalanceMC (amount lp tokens masterchef)',new BigNumber(lpTokenBalanceMC).div(DEFAULT_TOKEN_DECIMAL).toFormat(18))
  console.log('h-lpTotalSupply (amount lp tokens)',new BigNumber(lpTotalSupply).div(DEFAULT_TOKEN_DECIMAL).toFormat(18))
}

// console.log('farm',farmConfig.lpSymbol)
// console.log('h-tokenBalanceLP (main token amount in lp)',new BigNumber(newTokenBalanceLP).div(DEFAULT_TOKEN_DECIMAL).toFormat(18))
// console.log('h-quoteTokenBalanceLP (quote token amount pre-ratio)',new BigNumber(newQuoteTokenBalanceLP).div(DEFAULT_TOKEN_DECIMAL).toFormat(18))
// console.log('h-lpTotalSupply (amount lp tokens)',new BigNumber(lpTotalSupply).div(DEFAULT_TOKEN_DECIMAL).toFormat(18))
// console.log('h-lpTokenBalanceMC (amount lp tokens masterchef)',new BigNumber(lpTokenBalanceMC).div(DEFAULT_TOKEN_DECIMAL).toFormat(18))
      let tokenAmount
      let lpTotalInQuoteToken
      let tokenPriceVsQuote
      let quoteTokenAmount
      let lpTotalInQuoteTokenPCS = new BigNumber(0)
      const lpFactor = farmConfig.pid === 0 || farmConfig.pid === 1 ? 3 : 2

      if (farmConfig.isTokenOnly || farmConfig.isKingdomToken) {
        tokenAmount = farmConfig.isKingdomToken ? new BigNumber(kingdomSupply).div(new BigNumber(10).pow(tokenDecimals)) : new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(tokenDecimals));

        if(['amDAI', 'amUSDT', 'amUSDC'].includes(farmConfig.token.symbol) && ['amDAI', 'amUSDT', 'amUSDC'].includes(farmConfig.quoteToken.symbol)) {
          tokenPriceVsQuote = new BigNumber(1)
        } else {
          tokenPriceVsQuote = newQuoteTokenBalanceLP.div(new BigNumber(newTokenBalanceLP))
        }

        lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote)
      } else {
        // Ratio in % a LP tokens that are in staking, vs the total number in circulation
        let lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

// if (farmConfig.lpSymbol === 'POLYCUB-USDC LP') console.log('lpTokenRatio',lpTokenRatio.toNumber())

        // Total value in staking in quote token value
        lpTotalInQuoteToken = newQuoteTokenBalanceLP
          .div(DEFAULT_TOKEN_DECIMAL)
          .times(new BigNumber(lpFactor))
          .times(lpTokenRatio)

// if (farmConfig.lpSymbol === 'POLYCUB-USDC LP') console.log('lpTotalInQuoteToken',lpTotalInQuoteToken.toNumber())

        // Value of first token relative to the quote token
        // tokenPriceVsQuote = newQuoteTokenBalanceLP.div(new BigNumber(newTokenBalanceLP))
        tokenPriceVsQuote = farmConfig.pid === 1 ? new BigNumber(AAVE_TOKEN_USD) : newQuoteTokenBalanceLP.div(new BigNumber(newTokenBalanceLP))

// if (farmConfig.lpSymbol === 'POLYCUB-USDC LP') console.log('tokenPriceVsQuote',tokenPriceVsQuote.toNumber())

if (farmConfig.lpSymbol === 'lpfarm') console.log('h-lpTokenRatio (lp staked / supply)',lpTokenRatio.toFormat(18))
// console.log('h-lpTotalInQuoteToken',lpTotalInQuoteToken.div(DEFAULT_TOKEN_DECIMAL).toNumber())
if (farmConfig.lpSymbol === 'lpfarm') console.log('h-tokenPriceVsQuote (main token value in quote token)',tokenPriceVsQuote.toFormat(18))
        if (farmConfig.isKingdom) {
          // Store host farm lp total in quote token value
          lpTotalInQuoteTokenPCS = lpTotalInQuoteToken
if (farmConfig.lpSymbol === 'lpfarm') console.log('h-lpTotalInQuoteTokenPCS (total amount in quote token )',lpTotalInQuoteTokenPCS.toFormat(18))
          // lp total supply circulation vs supply in kingdom
          const ratioHostToKingdom = new BigNumber(lpTotalSupply).div(DEFAULT_TOKEN_DECIMAL).div(new BigNumber(kingdomSupply))

          const kingdomTokenSupply = new BigNumber(newTokenBalanceLP).div(new BigNumber(ratioHostToKingdom))

          const kingdomQuoteTokenSupply = newQuoteTokenBalanceLP.div(new BigNumber(ratioHostToKingdom))

          lpTokenRatio = new BigNumber(kingdomTokenSupply).div(new BigNumber(kingdomQuoteTokenSupply))

          lpTotalInQuoteToken = new BigNumber(kingdomQuoteTokenSupply)
            .div(DEFAULT_TOKEN_DECIMAL)
            .times(new BigNumber(lpFactor))

          if (farmConfig.pid === 1) {
            tokenAmount = new BigNumber(kingdomSupply)

            lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote)
          }

          lpTotalInQuoteToken = lpTotalInQuoteToken.isNaN() ? BIG_ZERO : lpTotalInQuoteToken
if (farmConfig.lpSymbol === 'lpfarm') {
console.log('k-kingdomSupply',kingdomSupply)
console.log('k-ratioHostToKingdom (h-total supply / k-supply)',ratioHostToKingdom.toFormat(18))
console.log('k-kingdomTokenSupply (h-main token / ratio h-k)',kingdomTokenSupply.div(DEFAULT_TOKEN_DECIMAL).toFormat(18))
console.log('k-kingdomQuoteTokenSupply (h-quote token / ratio h-k )',kingdomQuoteTokenSupply.div(DEFAULT_TOKEN_DECIMAL).toFormat(18))
console.log('k-lpTokenRatio (k-main token amt / k-quote token amt)',lpTokenRatio.toFormat(18))
console.log('k-lpTotalInQuoteToken (total in k-quote amt)',lpTotalInQuoteToken.toFormat(18))
}
        }
        // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
        tokenAmount = new BigNumber(newTokenBalanceLP).div(DEFAULT_TOKEN_DECIMAL).times(lpTokenRatio)
if (farmConfig.lpSymbol === 'lpfarm') console.log('k-tokenAmount (h-token amt * k-ratio)',new BigNumber(tokenAmount).toFormat(18))

        // Proper
        quoteTokenAmount = newQuoteTokenBalanceLP
          .times(lpTokenRatio)
if (farmConfig.lpSymbol === 'lpfarm') console.log('k-quoteTokenAmount post-ratio (h-quote amt * k-ratio)',new BigNumber(quoteTokenAmount).div(DEFAULT_TOKEN_DECIMAL).toFormat(18))

        // I think this is for single token vs lp token correction of token value
        // if(tokenAmount.comparedTo(0) > 0){
        //   tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount)
        // }else{
        //   tokenPriceVsQuote = newQuoteTokenBalanceLP.div(new BigNumber(newTokenBalanceLP))
        // }
        tokenPriceVsQuote = newQuoteTokenBalanceLP.div(new BigNumber(newTokenBalanceLP))
      }
if (farmConfig.lpSymbol === 'lpfarm') console.log('k-tokenPriceVsQuote',tokenPriceVsQuote.toFormat(18))

      //
      const tokenAmountTotal = new BigNumber(newTokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))

      const mCalls = [
        {
          address: getMasterChefAddress(),
          name: 'poolInfo',
          params: [farmConfig.pid],
        },
        {
          address: getMasterChefAddress(),
          name: 'totalAllocPoint',
        },
        {
          address: getMasterChefAddress(),
          name: 'tokensPerBlock',
        }
      ]

      const [info, totalAllocPoint, tokensPerBlock] = await multicall(masterchefABI, mCalls).catch(error => {
        throw new Error(`multicall nontoken: ${error}`)
      })
  // console.log(tokensPerBlock, new BigNumber(tokensPerBlock).toNumber())
  // console.log('totalAllocPoint',new BigNumber(totalAllocPoint).toFormat(18))
  // console.log('info.allocPoint._hex',new BigNumber(info.allocPoint._hex).toFormat(18))
  // console.log('poolWeigh',new BigNumber(info.allocPoint._hex).div(new BigNumber(totalAllocPoint)).toFormat(18))
      if (farmConfig.isKingdom) {
        const kCalls = [
          {
            address: getKingdomsAddress(),
            name: 'poolInfo',
            params: [farmConfig.pid],
          },
          {
            address: getKingdomsAddress(),
            name: 'totalAllocPoint',
          },
          {
            address: getMasterChefAddress(),
            name: 'tokensPerBlock',
          }
        ]

        const [kInfo, kTotalAllocPoint, kTokensPerBlock] = await multicall(kingdomsABI, kCalls).catch(error => {
          throw new Error(`multicall nontoken: ${error}`)
        })

        // const allocPoint = new BigNumber(kInfo.allocPoint._hex)
        // const kingdomTotalAlloc = new BigNumber(1350)

        // const kingdomCorrectAlloc = allocPoint.times(new BigNumber(kingdomTotalAlloc)).div(new BigNumber(kTotalAllocPoint))

        // const kingdomPoolWeight = kingdomCorrectAlloc.div(new BigNumber(totalAllocPoint))

        let poolWeightHost = new BigNumber(0)
        if (farmConfig.altPid || farmConfig.altPid === 0) {
          const hostMasterchef = getSushiAddress()
          const hostAbi = sushiABI

          const hostCalls = [
            {
              address: hostMasterchef,
              name: 'poolInfo',
              params: [farmConfig.altPid],
            },
            {
              address: hostMasterchef,
              name: 'totalAllocPoint',
            }
          ]

          const [infoHost, totalAllocPointHost] = await multicall(hostAbi, hostCalls).catch(error => {
            throw new Error(`multicall pcs error: ${error}`)
          })

          poolWeightHost = new BigNumber(infoHost.allocPoint._hex).div(new BigNumber(1)).div(new BigNumber(totalAllocPointHost))
// console.log('totalAllocPointHost',new BigNumber(totalAllocPointHost).toFormat(18))
// console.log('infoHost.allocPoint._hex',new BigNumber(infoHost.allocPoint._hex).toFormat(18))
// console.log('poolWeightHost',poolWeightHost.toFormat(18))
        }

        const kAllocPoint = new BigNumber(kInfo.allocPoint._hex)
        const kPoolWeight = kAllocPoint.div(new BigNumber(kTotalAllocPoint))
// console.log('kTotalAllocPoint',new BigNumber(kTotalAllocPoint).toFormat(18))
// console.log('kAllocPoint',kAllocPoint.toFormat(18))
// console.log('kPoolWeight',kPoolWeight.toFormat(18))
        return {
          ...farmConfig,
          tokenAmount: tokenAmount.toJSON(),
          lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
          lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
          tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
          poolWeight: kPoolWeight.toJSON(),
          multiplier: `${kAllocPoint.div(100).toString()}X`,
          depositFeeBP: kInfo.depositFeeBP,
          cubPerBlock: new BigNumber(kTokensPerBlock).div(DEFAULT_TOKEN_DECIMAL).toNumber(),
          lpTokenBalancePCS: new BigNumber(lpTokenBalanceMC).div(DEFAULT_TOKEN_DECIMAL).toNumber(),
          lpTotalInQuoteTokenPCS: lpTotalInQuoteTokenPCS.toNumber(),
          poolWeightPCS: poolWeightHost.toJSON(),
          kingdomSupply,
          tokenAmountTotal: tokenAmountTotal.toJSON(),
          curveAPR,
        }
      }

      const allocPoint = new BigNumber(info.allocPoint._hex)
      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))

// console.log('farm', farmConfig.lpSymbol)
// console.log('tokenAmount',tokenAmount.toFormat(18))
// console.log('lpTotalSupply',new BigNumber(lpTotalSupply).div(DEFAULT_TOKEN_DECIMAL).toFormat(18))
// console.log('lpTotalInQuoteToken',lpTotalInQuoteToken.div(DEFAULT_TOKEN_DECIMAL).toFormat(18))
// console.log('tokenPriceVsQuote',tokenPriceVsQuote.toFormat(18))
// console.log('poolWeight',poolWeight.toFormat(18))
// console.log('tokenAmountTotal',tokenAmountTotal.toFormat(18))

      return {
        ...farmConfig,
        tokenAmount: tokenAmount.toJSON(),
        // quoteTokenAmount: quoteTokenAmount.toJSON(),
        lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
        // tokenPriceVsQuote: quoteTokenAmount.div(tokenAmount).toJSON(),
        poolWeight: poolWeight.toJSON(),
        multiplier: `${allocPoint.div(100).toString()}X`,
        depositFeeBP: info.depositFeeBP,
        cubPerBlock: new BigNumber(tokensPerBlock).div(DEFAULT_TOKEN_DECIMAL).toNumber(),
        tokenAmountTotal: tokenAmountTotal.toJSON(),
      }
    }),
  )
  return data
}

export default fetchFarms
