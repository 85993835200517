// import contracts from './contracts'
import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 4,
    lpSymbol: 'POLYCUB-USDC LP',
    lpAddresses: {
      137: '0x5AeFd5C04ed6DBd856A5aeB691eFcc80c0aB7472',
    },
    vaultAddress: '0x96e879f69dc6098ebbfd13eb5b9d308f3241336e',
    token: tokens.cub,
    quoteToken: tokens.usdc,
  },
  {
    pid: 5,
    lpSymbol: 'POLYCUB-WETH LP',
    lpAddresses: {
      137: '0xc9ba162d07d762ad4c2a759cf806f8650b6c9a93',
    },
    vaultAddress: '0x895b30415afb8c2a81ff5a24552bd93c136963f3',
    token: tokens.cub,
    quoteToken: tokens.weth,
  },
  {
    pid: 6,
    lpSymbol: 'pLEO-WMATIC LP',
    lpAddresses: {
      137: '0xf8095ffd24f02bd8aedc96e5a3617310815cc4c7',
    },
    vaultAddress: '0x344A799e06647eb6d13f5AA621f73d3431ad8F2e',
    token: tokens.pleo,
    quoteToken: tokens.wmatic,
  },
  {
    pid: 8,
    lpSymbol: 'pHBD-USDC LP',
    lpAddresses: {
      137: '0x4414B5a6356e9F1a5DaAde9eB5F3fe9e37Af1635',
    },
    vaultAddress: '0x3868C6E43B6C91424F6b1Cd140B95F190E26f9fC',
    token: tokens.phbd,
    quoteToken: tokens.usdc,
  },
  {
    pid: 10,
    lpSymbol: 'pHIVE-POLYCUB LP',
    lpAddresses: {
      137: '0x29f13baded9ade12acc4069a8ffeef95146b8bc9',
    },
    vaultAddress: '0x6c61aa1b4ba77e58eaf57a6c35e03fbefe24f71c',
    token: tokens.phive,
    quoteToken: tokens.cub,
  },
  {
    pid: 11,
    lpSymbol: 'pSPS-POLYCUB LP',
    lpAddresses: {
      137: '0xf89261d86c6e8bd4c719f1fd769220dfc746d67a',
    },
    vaultAddress: '0xd18a5605657126cc82b0d8c76e84492e0e9b221d',
    token: tokens.psps,
    quoteToken: tokens.cub,
  },
  {
    pid: 12,
    lpSymbol: 'pHBD-POLYCUB LP',
    lpAddresses: {
      137: '0x8a3f448f7cfad19581b9876d695554897ac334b9',
    },
    vaultAddress: '0xe91560E239fF1BD5527aBB95ca15D2037e1A879f',
    token: tokens.phbd,
    quoteToken: tokens.cub,
  },

  // {
  //   pid: 0,
  //   compounding: 365,
  //   isKingdom: true,
  //   farmType: 'Sushi',
  //   lpSymbol: 'WETH-WMATIC LP',
  //   lpAddresses: {
  //     137: '0xc4e595acDD7d12feC385E5dA5D43160e8A0bAC0E',
  //   },
  //   token: tokens.weth,
  //   quoteToken: tokens.wmatic,
  //   kingdomContract: '0x4E61bdF08995C760C040498E6CBF58b63a3EbB62',
  // },
  {
    pid: 2,
    // pid: 0, // test
    altPid: 3,
    compounding: 365,
    isKingdom: true,
    farmType: 'Sushi',
    lpSymbol: 'WETH-WBTC LP',
    lpAddresses: {
      137: '0xe62ec2e799305e0d367b0cc3ee2cda135bf89816',
    },
    token: tokens.wbtc,
    quoteToken: tokens.weth,
    kingdomContract: '0x1E3C18Fd954B949AE7AC2d51443F03C162414c47',
    // kingdomContract: '0x35f38a37e3e6c0c9d5bae20cf9a89a0234d847b9', // test
  },
  {
    pid: 3,
    // pid: 1, // test
    altPid: 5,
    compounding: 365,
    isKingdom: true,
    farmType: 'Sushi',
    lpSymbol: 'WETH-DAI LP',
    lpAddresses: {
      137: '0x6ff62bfb8c12109e8000935a6de54dad83a4f39f',
    },
    token: tokens.weth,
    quoteToken: tokens.dai,
    kingdomContract: '0x878AA3d8F7E42BDf8b3c158a7Fd0B7E3ef05D323',
    // kingdomContract: '0x0f0b828d02961a9840c4e606ae678b37058e1152', // test
  },
  {
    pid: 0,
    // pid: 2, // test
    compounding: 365,
    isKingdom: true,
    farmType: 'Curve',
    lpSymbol: 'USD-BTC-ETH LP',
    lpAddresses: {
      // 137: '0x92215849c439E1f8612b6646060B4E3E5ef822cC', // values
      137: '0xdAD97F7713Ae9437fa9249920eC8507e5FbB23d3',
    },
    token: tokens.amwbtc,
    quoteToken: tokens.am3crv,
    kingdomContract: '0xfbecfd9dee14f6551733b9079e2aaddec5db3b08',
    // kingdomContract: '0x603f989821AF7FEe667d204205935E599Be57Ab3', // test
    totalsAddress: '0xdAD97F7713Ae9437fa9249920eC8507e5FbB23d3',
    tokenTotalsAddress: '0x92215849c439E1f8612b6646060B4E3E5ef822cC',
  },
  {
    pid: 1,
    compounding: 365,
    isKingdom: true,
    farmType: 'Curve',
    lpSymbol: 'DAI-USDT-USDC LP',
    lpAddresses: {
      // 137: '0x19793B454D3AfC7b454F206Ffe95aDE26cA6912c', // values
      137: '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171',
    },
    token: tokens.amUSDT,
    quoteToken: tokens.amDAI,
    kingdomContract: '0x31a0937cdaaf63d8f8f180d6b61733c99ba85184',
    totalsAddress: '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171',
    // tokenTotalsAddress: '0x19793B454D3AfC7b454F206Ffe95aDE26cA6912c',
    tokenTotalsAddress: '0x445FE580eF8d70FF569aB36e80c647af338db351',
  },
]

export default farms
