import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { filterFarmsByQuoteToken } from 'utils/farmsPriceHelpers'
import { Farm } from 'state/types'
import getCoinGeckoApi from 'utils/getCoinGeckoApi'
// import { useCoinGeckoApi } from 'state/hooks'

const getFarmFromTokenSymbol = (farms: Farm[], tokenSymbol: string, preferredQuoteTokens?: string[]): Farm => {
  const farmsWithTokenSymbol = farms.filter((farm) => farm.token.symbol === tokenSymbol)
  const filteredFarm = filterFarmsByQuoteToken(farmsWithTokenSymbol, preferredQuoteTokens)
  return filteredFarm
}

export const getFarmBaseTokenPrice = (farm: Farm, quoteTokenFarm: Farm, bnbPriceBusd: BigNumber): BigNumber => {
  const hasTokenPriceVsQuote = Boolean(farm.tokenPriceVsQuote)

  if (farm.quoteToken.symbol === 'DAI' || farm.quoteToken.symbol === 'USDC' || farm.quoteToken.symbol === 'am3CRV') {
    return hasTokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (farm.quoteToken.symbol === 'WETH') {
    return hasTokenPriceVsQuote ? bnbPriceBusd.times(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  // We can only calculate profits without a quoteTokenFarm for BUSD/BNB farms
  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  // Possible alternative farm quoteTokens:
  // UST (i.e. MIR-UST), pBTC (i.e. PNT-pBTC), BTCB (i.e. bBADGER-BTCB), ETH (i.e. SUSHI-ETH)
  // If the farm's quote token isn't BUSD or wBNB, we then use the quote token, of the original farm's quote token
  // i.e. for farm PNT - pBTC we use the pBTC farm's quote token - BNB, (pBTC - BNB)
  // from the BNB - pBTC price, we can calculate the PNT - BUSD price
  if (quoteTokenFarm.quoteToken.symbol === 'WETH') {
    const quoteTokenInBusd = bnbPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote)
    return hasTokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
      : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'DAI') {
    const quoteTokenInBusd = quoteTokenFarm.tokenPriceVsQuote
    return hasTokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
      : BIG_ZERO
  }

  if (quoteTokenFarm.token.symbol === 'POLYCUB') {
    const quoteTokenInBusd = quoteTokenFarm.tokenPriceVsQuote
    return hasTokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd)
      : BIG_ZERO
  }

  // Catch in case token does not have immediate or once-removed BUSD/wBNB quoteToken
  return BIG_ZERO
}

export const getFarmQuoteTokenPrice = (farm: Farm, quoteTokenFarm: Farm, bnbPriceBusd: BigNumber): BigNumber => {
  if (['DAI', 'USDC', 'am3CRV', 'amDAI', 'amUSDT', 'amUSDC'].includes(farm.quoteToken.symbol)) {
    return new BigNumber(1)
  }

  if (farm.quoteToken.symbol === 'WETH') {
    return bnbPriceBusd
  }

  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'WETH') {
    return quoteTokenFarm.tokenPriceVsQuote ? bnbPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'DAI') {
    return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (quoteTokenFarm.token.symbol === 'POLYCUB') {
    return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  return BIG_ZERO
}

const fetchFarmsPrices = async (farms) => {
  const maticPrice = await getCoinGeckoApi('https://api.coingecko.com/api/v3/coins/wmatic')
  // console.log('farms',farms)
  const ethDaiFarm = farms.find((farm: Farm) => farm.lpSymbol === 'WETH-DAI LP') // SUSHI-WETH-DAI
  const ethPriceDai = ethDaiFarm.tokenPriceVsQuote ? new BigNumber(1).times(ethDaiFarm.tokenPriceVsQuote) : BIG_ZERO
  // console.log('ethPriceDai',ethPriceDai.toFormat(10))

  // const pCubUsdcFarm = farms.find((farm: Farm) => farm.lpSymbol === 'POLYCUB-USDC LP') // SUSHI-WETH-DAI
  // const pCubPriceUsdc = pCubUsdcFarm.tokenPriceVsQuote ? new BigNumber(1).times(pCubUsdcFarm.tokenPriceVsQuote) : BIG_ZERO
  // console.log('pCubPriceUsdc',pCubPriceUsdc.toFormat(10))

  const farmsWithPrices = farms.map((farm) => {
    // console.log('farm',farm.lpSymbol)
    // console.log('farm',farm)
    // const baseFarmPrice = farm.quoteToken.symbol === 'POLYCUB' ? pCubPriceUsdc : ethPriceDai
    const quoteTokenFarm = getFarmFromTokenSymbol(farms, farm.quoteToken.symbol)
    // console.log('quoteTokenFarm',quoteTokenFarm)
    const baseTokenPrice = getFarmBaseTokenPrice(farm, quoteTokenFarm, ethPriceDai)
    // console.log('baseTokenPrice',baseTokenPrice.toFormat(10))
    const quoteTokenPrice = getFarmQuoteTokenPrice(farm, quoteTokenFarm, ethPriceDai)
    // console.log('quoteTokenPrice',quoteTokenPrice.toFormat(10))
    const token = { ...farm.token, busdPrice: baseTokenPrice.toJSON() }
    let quoteToken = { ...farm.quoteToken, busdPrice: quoteTokenPrice.toJSON() }

    if (farm.quoteToken.symbol === 'WMATIC') {
      quoteToken = { ...quoteToken, busdPrice: maticPrice }
    }

    return { ...farm, token, quoteToken }
  })

  return farmsWithPrices
}

export default fetchFarmsPrices
