import axios from 'axios'

const getCoinGeckoApi =  async (
  api = 'https://api.coingecko.com/api/v3/coins/wmatic',
) => {
  const dataAPI:any = await axios.get(api, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }).catch(error => console.log('API error getting data from coingecko', error));
  // console.log('dataAPI',dataAPI)
  const price = dataAPI.data.market_data.current_price.usd
  // console.log('price',price)
  return price
}

export default getCoinGeckoApi
