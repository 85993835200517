import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://app.sushi.com/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://app.sushi.com/add',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  // {
  //   label: 'Dens',
  //   icon: 'PoolIcon',
  //   href: '/dens',
  // },
  {
    label: 'Kingdoms',
    icon: 'PawIcon',
    href: '/kingdoms',
    status: {
      text: 'AUTO',
      color: 'warning',
    },
  },
  {
    label: 'xPOLYCUB',
    icon: 'PoolIcon',
    href: '/staking',
  },
  {
    label: 'Governance',
    icon: 'PawIcon',
    href: '/governance',
  },
  // {
  //   label: 'IDO',
  //   icon: 'IfoIcon',
  //   href: '/ido',
  // },
  {
    label: 'LeoBridge',
    icon: 'NftIcon',
    href: "https://bridge.cubdefi.com",
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'GitHub',
        href: 'https://github.com/CubFinance',
      },
      {
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com/currencies/polycub/',
      },
      {
        label: 'CoinGecko',
        href: 'https://www.coingecko.com/en/coins/polycub',
      },
      {
        label: 'Cointelegraph',
        href: 'https://cointelegraph.com/news/new-application-merges-defi-20-protocols-with-yield-optimization-on-polygon',
      },
      {
        label: 'Bitcoin.com',
        href: 'https://news.bitcoin.com/leofinance-founder-khal-kazi-discusses-polycub-and-how-defi-and-web3-can-change-your-life/',
      },
    ],
  },
  {
    label: 'Docs',
    icon: 'TicketIcon',
    href: 'https://docs.polycub.com/',
  },
  {
    label: 'Roadmap',
    icon: 'MoreIcon',
    href: 'https://docs.polycub.com/roadmap',
  },
  {
    label: "Tokenized Blogging",
    icon: "TokenizeIcon",
    href: "https://leofinance.io/",
  },
  {
    label: "Blog",
    icon: "BlogIcon",
    href: "https://leofinance.io/@leofinance",
  },
  {
    label: 'CertiK Audit',
    icon: 'AuditIcon',
    href: 'https://docs.polycub.com/resources/certik-audit',
    status: {
      text: 'LIVE',
      color: 'success',
    },
  },
]

export default config
