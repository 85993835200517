const tokens = {
  cub: {
    symbol: 'POLYCUB',
    address: {
      137: '0x7cc15fef543f205bf21018f038f591c6bada941c',
    },
    decimals: 18,
    projectLink: 'https://cubdefi.com/',
  },
  xpolycub: {
    symbol: 'xPOLYCUB',
    address: {
      137: '0x905e21f6c4cb1ad789ced61cd0734590a4542346',
    },
    decimals: 18,
    projectLink: 'https://cubdefi.com/',
  },
  vexpolycub: {
    symbol: 'vexPOLYCUB',
    address: {
      137: '0x60696A2650281eB804bBBFf2a43DB301C61FA59B',
    },
    decimals: 18,
    projectLink: 'https://cubdefi.com/',
  },
  pleo: {
    symbol: 'pLEO',
    address: {
      137: '0xF826A91e8De52bC1Baf40d88203E572DC2551aa3',
    },
    decimals: 18,
    projectLink: 'https://polygon.curve.fi/atricrypto3',
  },
  wbnb: {
    symbol: 'BNB',
    address: {
      137: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  sushi: {
    symbol: 'SUSHI',
    address: {
      137: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
    },
    decimals: 18,
    projectLink: 'https://cubdefi.com/',
  },
  wmatic: {
    symbol: 'WMATIC',
    address: {
      137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    },
    decimals: 18,
    projectLink: 'https://cubdefi.com/',
  },
  weth: {
    symbol: 'WETH',
    address: {
      137: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    },
    decimals: 18,
    projectLink: 'https://cubdefi.com/',
  },
  wbtc: {
    symbol: 'WBTC',
    address: {
      137: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
    },
    decimals: 8,
    projectLink: 'https://cubdefi.com/',
  },
  quick: {
    symbol: 'QUICK',
    address: {
      137: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
    },
    decimals: 18,
    projectLink: 'https://cubdefi.com/',
  },
  dai: {
    symbol: 'DAI',
    address: {
      137: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    },
    decimals: 18,
    projectLink: 'https://cubdefi.com/',
  },
  curve: {
    symbol: 'CRV',
    address: {
      137: '0x172370d5cd63279efa6d502dab29171933a610af',
    },
    decimals: 18,
    projectLink: 'https://cubdefi.com/',
  },
  usdc: {
    symbol: 'USDC',
    address: {
      137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    },
    decimals: 6,
    projectLink: 'https://polygon.curve.fi/aave',
  },
  amwbtc: {
    symbol: 'amWBTC',
    address: {
      137: '0x5c2ed810328349100a66b82b78a1791b101c9d61',
    },
    decimals: 8,
    projectLink: 'https://polygon.curve.fi/atricrypto3',
  },
  amweth: {
    symbol: 'amWETH',
    address: {
      137: '0x28424507fefb6f7f8e9d3860f56504e4e5f5f390',
    },
    decimals: 18,
    projectLink: 'https://polygon.curve.fi/atricrypto3',
  },
  am3crv: {
    symbol: 'am3CRV',
    address: {
      137: '0xe7a24ef0c5e95ffb0f6684b813a78f2a3ad7d171',
      // 137: '0x445FE580eF8d70FF569aB36e80c647af338db351',
    },
    decimals: 18,
    projectLink: 'https://polygon.curve.fi/atricrypto3',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    },
    decimals: 6,
    projectLink: 'https://polygon.curve.fi/aave',
  },
  aave: {
    symbol: 'Aave',
    address: {
      137: '0xd6df932a45c0f255f85145f286ea0b292b21c90b',
    },
    decimals: 18,
    projectLink: 'https://polygon.curve.fi/aave',
  },
  amDAI: {
    symbol: 'amDAI',
    address: {
      137: '0x27f8d03b3a2196956ed754badc28d73be8830a6e',
    },
    decimals: 18,
    projectLink: 'https://polygon.curve.fi/aave',
  },
  amUSDT: {
    symbol: 'amUSDT',
    address: {
      137: '0x60d55f02a771d515e077c9c2403a1ef324885cec',
    },
    decimals: 18,
    projectLink: 'https://polygon.curve.fi/aave',
  },
  amUSDC: {
    symbol: 'amUSDC',
    address: {
      137: '0x1a13f4ca1d028320a707d99520abfefca3998b7f',
    },
    decimals: 18,
    projectLink: 'https://polygon.curve.fi/aave',
  },
  phbd: {
    symbol: 'pHBD',
    address: {
      137: '0x6d969cea201e427d2875724fd4e8044833fbc7f4',
    },
    decimals: 18,
    projectLink: 'https://polygon.curve.fi/aave',
  },
  phive: {
    symbol: 'pHIVE',
    address: {
      137: '0x456320e9b87a7c84a53b8ab300067f3a29aab301',
    },
    decimals: 18,
    projectLink: 'https://polygon.curve.fi/aave',
  },
  psps: {
    symbol: 'pSPS',
    address: {
      137: '0x28cEAd9E4ff96806C79f4189eF28FC61418e2216',
    },
    decimals: 18,
    projectLink: 'https://polygon.curve.fi/aave',
  },
}

export default tokens
