import poolsConfig from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import cakeABI from 'config/abi/cake.json'
import wbnbABI from 'config/abi/weth.json'
import multicall from 'utils/multicall'
import masterchefABI from 'config/abi/masterchef.json'
import vexPCubABI from 'config/abi/vexpcub.json'
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL } from 'config'

export const fetchPoolsBlockLimits = async () => {
  const poolsWithEnd = poolsConfig.filter((p) => p.sousId !== 0)
  const callsStartBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'startBlock',
    }
  })
  const callsEndBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'bonusEndBlock',
    }
  })

  const starts = await multicall(sousChefABI, callsStartBlock)
  const ends = await multicall(sousChefABI, callsEndBlock)

  return poolsWithEnd.map((cakePoolConfig, index) => {
    const startBlock = starts[index]
    const endBlock = ends[index]
    return {
      sousId: cakePoolConfig.sousId,
      startBlock: new BigNumber(startBlock).toJSON(),
      endBlock: new BigNumber(endBlock).toJSON(),
    }
  })
}

export const fetchPoolsTotalStaking = async () => {
  const nonBnbPools = poolsConfig.filter((p) => p.stakingToken.symbol !== 'BNB')

  const callsNonBnbPools = nonBnbPools.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.stakingToken.address),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    }
  })

  // callsNonBnbPools.push({
  //   address: '0x905e21f6c4cb1ad789ced61cd0734590a4542346',
  //   name: 'totalSupply',
  //   params: []
  // })

  const nonBnbPoolsTotalStaked = await multicall(cakeABI, callsNonBnbPools)
  // console.log('nonBnbPoolsTotalStaked',nonBnbPoolsTotalStaked)

  const callsTotalSupply = nonBnbPools.map((poolConfig) => {
    return {
      address: poolConfig.contractAddress[137],
      name: 'totalSupply',
    }
  })
  const totalSupply = await multicall(cakeABI, callsTotalSupply)

  const callsPerBlock = nonBnbPools.map((poolConfig) => {
    return {
      address: getMasterChefAddress(),
      name: 'tokensPerBlock',
    }
  })
  const cubPerBlock = await multicall(masterchefABI, callsPerBlock)

  const callsUnlockTime = nonBnbPools.map((poolConfig) => {
    if (poolConfig.sousId !== 0) return {
        address: poolConfig.contractAddress[137],
        name: 'unlockTime',
      }
    return null
  })
  const cUnlock = callsUnlockTime.filter((c) => c !== null)
  const unlockTime = await multicall(vexPCubABI, cUnlock)

  return [
    ...nonBnbPools.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(nonBnbPoolsTotalStaked[index]).toJSON(),
      totalSupply: new BigNumber(totalSupply[index]).toJSON(),
      cubPerBlock: new BigNumber(cubPerBlock[index]).div(DEFAULT_TOKEN_DECIMAL).times(0.3).toJSON(),
      unlockTime: p.sousId !== 0 ? new BigNumber(unlockTime).toNumber() : undefined,
    })),
  ]
}
