import { CAKE_PER_BLOCK } from 'config'
import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'
// import { PoolConfig } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    // stakingToken: tokens.xpolycub,
    // earningToken: tokens.xpolycub,
    token: 'xpolycub',
    stakingToken: tokens.cub,
    earningToken: tokens.xpolycub,
    contractAddress: {
      137: '0x905e21f6c4cb1ad789ced61cd0734590a4542346'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: CAKE_PER_BLOCK.toString(),
    sortOrder: 1,
    isFinished: false,
    description: 'Stake xPOLYCUB to earn yield/early harvesting penalties and gain governance power',
    ratioText: 'PC/xPC',
  },
  {
    sousId: 1,
    // stakingToken: tokens.xpolycub,
    // earningToken: tokens.xpolycub,
    token: 'vexpolycub',
    stakingToken: tokens.xpolycub,
    earningToken: tokens.vexpolycub,
    contractAddress: {
      137: '0x472a65cA0D76a938925d8a373b793C99379ABd83'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    // tokenPerBlock: CAKE_PER_BLOCK.times(0.3).toString(),
    tokenPerBlock: CAKE_PER_BLOCK.toString(),
    sortOrder: 2,
    isFinished: false,
    description: 'Voting escrow xPOLYCUB: Lock your xPOLYCUB for Xdays to gain 3x governance power and 20% fixed APR',
    ratioText: 'xPC/vexPC',
  },
  /* {
    sousId: 0,
    stakingToken: tokens.cake,
    earningToken: tokens.cake,
    contractAddress: {
      97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 14,
    stakingToken: tokens.cake,
    earningToken: tokens.hard,
    contractAddress: {
      97: '',
      56: '0x90F995b9d46b32c4a1908A8c6D0122e392B3Be97',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.346',
    sortOrder: 999,
    isFinished: true,
  },
  {
    sousId: 13,
    stakingToken: tokens.cake,
    earningToken: tokens.broobee,
    contractAddress: {
      97: '',
      56: '0xdc8c45b7F3747Ca9CaAEB3fa5e0b5FCE9430646b',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '12.5',
    sortOrder: 999,
    isFinished: true,
  },
*/
]

export default pools
